.sub-container{margin-top:10px;padding: 10px;min-height: 150px;}
table tr td{padding: 0px;border:none;}
label{font-weight: 600; color: #2E4053;}
.form-control{height: 35px;}

.uploading-stat{font-size: 15px; color:#148F77; font-weight: 600;}

.uploading-stat .spinner{
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.dhis-summary-main{padding: 5px;background: #D4EFDF; border-radius: 5px;margin-bottom: 5px;}
.dhis-summary-main h6{font-weight: 600; color: #0E6655;}
#dhisSummaryTbl{margin-bottom: 0px;}
#dhisSummaryTbl tbody tr td{font-size: 13px;padding:5px;}
.count-main{padding:5px;}
.count-sec{width: 100%;height: 100%;background: #CACFD2;padding: 5px 8px;border-radius: 5px;font-weight: 600;position: relative;}
.count-val{position: absolute;top:5px;right:8px;}


.cserr-main{padding: 5px;background: #FADBD8; border-radius: 5px;}
.cserr-main h6{font-weight: 600; color: #C0392B;}
#cserrTbl{background: #FFF;margin-bottom: 0px;}
#cserrTbl thead tr th{padding: 5px !important;font-size: 13px; color: #34495E;}
#cserrTbl tbody tr td{padding: 5px !important;font-size: 13px; color: #34495E;}