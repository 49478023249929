.des-item{font-size:14px;background-color: #FFF;padding: 10px 20px 10px 10px;margin-bottom: 10px;border-radius: 5px;position: relative;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;}
.des-item span{position: absolute;top:5px; right:5px;}
.des-item span:hover{cursor: pointer;}
.year-txt{width: 100px !important;}

.add-btn{    
    width:"100%";
    margin-top: 0px;
    font-size: 15px;
    padding: 5px 10px;
}

.main-des-col{background: #ECF0F1; padding: 10px; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;}
.main-des-col h6{font-weight: 600; color: #283747;}

.modal-body{overflow-x: hidden;}

.lang-item label{font-size: 12px;}
.lang-item h5{font-size: 15px;}

.desclickitem:hover{cursor: pointer;}