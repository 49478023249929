
.main-container{margin-top:65px; margin-right: 20px; background: #FFF;padding:10px;}
.fs-select{width:250px !important;}

.generating-section-pop{font-family: 'Roboto', sans-serif;position: relative;padding: 0px !important;margin-left:0px; background: #FFF; min-height: 100px;border-top: 1px solid #ECF0F1;width: 790px;}
.generating-section-pop .gs-title{font-family: 'Roboto', sans-serif;}
.generating-section-pop .fsheet-logo{position: absolute;height: 60px;top: 7px;right: 45px;}
.main-heading-pop{margin-bottom: 2px;font-weight: 700;color: #111D5E;font-size: 22px;}
.sub-heading-pop{margin-bottom: 2px;font-weight: 700;color: #1f3994;font-size: 18px;}
.gs-title.sub-lang .main-heading-pop{font-size: 20px;}
.gs-title.sub-lang .sub-heading-pop{font-size: 16px;}
.generating-section-pop .overlay-bg{position: absolute;width: 120%;background: linear-gradient(to right, transparent, rgba(130, 69, 210, .3));height: 21rem;border-radius: 15rem;left: -6rem;top: -8rem;transform: rotate(5deg);}

.chart-title-col img{position: absolute; right:30px;width: 90px;top:-20px}
.sub-title{font-family: 'Roboto', sans-serif;color: #16A085;font-weight: 700;font-size: 16px;}

.newcase-tbl{margin-bottom: 0px;margin-top: 30px;}
.newcase-tbl tr td{text-align: center;}
.newcase-tbl img{margin-bottom: 10px;}

.summary-section-pop{padding-top: 10px;}
.summaryitem-pop-main{padding: 0px 5px;}
.summary-item-pop{padding:8px 15px;border-radius: 8px;position: relative;overflow: hidden;}
.summary-item-pop.asia{background: linear-gradient(170deg, #fe2a82, #fe2a52);}
.summary-item-pop.sl{background: linear-gradient(170deg, #8647ff, #066dcc);}
.summary-item-pop .img-col{text-align: center;position: relative;}
.summary-item-pop img{width: 25%;position: absolute;opacity: 0.6;top: 0px;right: 5px;}
.summary-item-pop .data-col{padding:10px;}
.summary-item-pop h6{color: #FFF;font-size: 16px; text-transform: uppercase;margin-bottom: 0px;font-weight: 600;margin-top: 6px;}
.summary-item-pop h5{color: #FFF;font-size: 20px; text-transform: capitalize; margin-bottom: 2px;margin-top: 1px;font-weight: 600;}
.summary-item-pop h5 small{display: block;font-size: 16px;color: rgba(255, 255, 255, .8);}

.pop-based .chart-title-col{background: #6930C3;color: #fff;font-size: 20px !important;padding: 10px 38px;}
.pop-based .chart-title-col.sub-lang{font-size: 15px!important;padding: 13px 150px 13px 38px;}
.pop-based .chart-title-col.sub-lang2{font-size: 14px !important;padding: 14px 38px;padding-right: 180px;}
.pop-based .subchart-content h6{text-align: center;margin:0px;margin-top: 5px;font-size: 12px;font-weight: 600;color: #fff;background: #033FFF;padding: 5px;width: 100px;padding-left: 20px;margin-left: -28px;border-radius: 15px;}
.pop-based .subchart-content h6.fmale{background: #FF008B;}

.pop-based .sub-title{color: #6930C3;}
.chart-containt-labels{margin-top: -20px;margin-left: 20px;position: absolute;}
.chart-containt-labels .col-6, .chart-containt-labels .col-4, .chart-containt-labels .col-8{font-size: 12px;padding-left: 20px;font-weight: 600;}
.chart-containts.sub-lang .chart-containt-labels .col-6, .chart-containts.sub-lang .chart-containt-labels .col-4, .chart-containts.sub-lang .chart-containt-labels .col-8{font-size: 11px;}
.chart-containt-labels .label-color{position: absolute;width: 10px;height: 10px;background: #ccc;border-radius: 4px;margin-top: 4px;margin-left: -15px;}
.chart-containt-labels .label-color.im{background: #6807F9;}
.chart-containt-labels .label-color.mm{background: #54E346;}
.chart-containt-labels .label-color.if{background: #F30067;}
.chart-containt-labels .label-color.mf{background: #72147E;}
.chart-containt-labels .label-color.mnc{background: #033FFF;}
.chart-containt-labels .label-color.pb{background: #54E346;}
.chart-containt-labels .label-color.fnc{background: #FF008B;}
.chart-containt-labels .label-color.mle{background: #0E49B5;}
.chart-containt-labels .label-color.fmle{background: #F21170;}


.chart-containts.sub-lang .summary-item-pop h5 small{font-size: 14px;}
.chart-containts.sub-lang .sub-title{font-size: 13px;font-weight: 800;}