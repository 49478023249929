@import url(./assets/css/roboto.css?family=Roboto:300,400,600,800);

body{font-family: 'Roboto', sans-serif;min-height: 100%;background: #e6e6e6;}
body.s-page{background: linear-gradient(145deg, rgb(82, 5, 123), rgb(82, 5, 123), rgb(157, 1, 145));}

.col-centered{margin:0 auto;float:none;}
.centered{position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);}

.alert{position: fixed;z-index:1999;left: 50%;transform: translate(-50%,25px);border:none;font-weight: 600;font-size: 14px;color:#0F3057;padding: 10px 30px;}
.alert span{margin-right: 15px;}
.alert .close{opacity: 0.5;text-shadow: none;cursor: pointer;margin-bottom: 0px;font-size: 1.3rem;position: absolute;margin-top: -5px;right: 15px;}
.alert .close:hover{opacity: 1;}
.alert-danger{background: #F1654C;}
.alert-success{background: #2CC990;}
.alert-info{background: #44BBFF;}
.alert-warning{background: #FFC153;}

.navbar-main{position: fixed;width: 100%;padding: 0px 0px 0px 0px;z-index: 9;}
.navbar{box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);width: 100%;height: 60px;padding-left: 5px;}
body.d-mode .navbar, body.d-mode .navbar .btn{color:#009788;}
.navbar .navbar-toggle{margin-right:0px;width: 65px;padding: 10px;transition: all 0.3s;display: inline-block;text-align: center;}
.navbar .navbar-toggle:hover{cursor: pointer;background: #f2f2f2;}
.navbar .navbar-brand{margin-left: 15px;font-weight: 800;color: #2980B9;font-size: 16px;}
.navbar .navbar-brand img{margin-right: 10px;height: 35px;margin-left: 10px;}
.navbar.bg-light{background-color: #fff !important;}
.navbar .dropdown-toggle{box-shadow: none;outline: none;border: none;}

.navbar .dropdown-menu{right: 0px;left: auto;top: 60px;margin: 0px;border-radius: 0px;width: 185px;}
.navbar .dropdown-menu .dropdown-item{padding: 5px 15px;font-size: 14px;}
.usernav-link{margin-right: 0px !important;padding: 18px 14px;width: 45px;border-left: 1px solid #eee;}
.usernav-link:hover{cursor: pointer;background: #f2f2f2;}
.usernav-link .dropdown .dropdown-toggle::after{content: none !important;}
.msgedrop-main .dropdown-menu{width: 320px;padding: 15px;}
.msgedrop-main .dropdown-menu h4{font-size: 18px;}
.msgedrop-main .dropdown-menu .msg-link{border: 1px solid #eee;margin-bottom: 2px;padding-top: 15px;}
.msgedrop-main .dropdown-menu .dropdown-item label{font-weight: 600;color: #5128a0;font-size: 12px;}
.msgedrop-main .dropdown-menu .dropdown-item .col{height: 40px;padding-left: 40px;font-weight: 600;line-height: 15px;}
.msgedrop-main .dropdown-menu .dropdown-item .col small{color: #999;font-size: 11px;}
.msgedrop-main .dropdown-menu .dropdown-item .col img{height: 30px;border-radius: 15px;position: absolute;margin-left: -40px;}

.breadcrumb li{font-size: 14px;font-weight: 600;}
.breadcrumb li a{text-decoration: none;color: #5128a0 !important;}
.stat-badge{font-weight: 400;font-size: 14px;margin-top: 10px;padding: 8px 15px;}
.badge-success{background: #5128a0;} .badge-danger{background: #dc3545;}

.App{overflow-x: hidden;}
.App.loggedout{height: calc(100vh - 0px);}
.main-container{margin-top:65px; margin-right: 20px;padding:10px;border-radius: 5px;box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;margin-bottom: 20px;}

.main-wrapper-container{margin-left:235px;}
body.s-page .main-wrapper-container{margin-left: 0px;}

.div-pagination{margin-top: 10px;}
.div-pagination .btn{border: 1px solid #BDC3C7; font-weight: 500;color: #5D6D7E;}
.div-pagination .btn:hover{background:linear-gradient(145deg, rgb(82, 5, 123), rgb(82, 5, 123), rgb(157, 1, 145));color: #FFF;}

@media (max-width: 992px) { 
    
}

@media (max-width: 767px) {
    
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius:5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  ::placeholder {
    font-size: 12px;
    color: #B3B6B7 !important;
  }

.main-heading{font-weight: 600; color:#5B2C6F;position: relative;}

.main-table{margin-bottom: 5px;margin-top: 5px;}
.main-table thead tr th{font-size: 12px;background: #CACFD2;text-align: center;}
.main-table tbody tr td{font-size: 14px;font-weight: 600;color: #34495E;}
.main-table .btn{padding: 2px 5px; border-radius: 5px;background-color: #CACFD2;border: none;color: #34495E;}
.main-table .btn:hover{color: #FFF;background-color: #2980B9;}
.textarea1{width: 100%; height: 60px;border:1px solid #BDC3C7; border-radius: 3px;padding: 5px;}
.main-table tbody tr td:hover{cursor: pointer;}
.main-table tbody tr td .delete:hover{cursor: pointer;color: crimson; font-weight: 700;}

.modal-header{padding: 5px 10px;background: linear-gradient(145deg, rgb(82, 5, 123), rgb(82, 5, 123), rgb(157, 1, 145));}
.modal-header .modal-title{font-size: 20px; font-weight: 600;color:#FFF;}
.modal-body{padding: 10px;  max-height: calc(100vh - 150px); overflow-y: auto;}
.modal-footer{padding: 5px 10px;}

.primary-button{background:linear-gradient(145deg, rgb(82, 5, 123), rgb(82, 5, 123), rgb(157, 1, 145)); color: #FFF;}
.form-group{margin-bottom: 10px;}
.form-group .form-label{font-size: 13px;font-weight: 600;margin-bottom: 0px;}
.form-group .form-control{height: 30px;font-size: 15px;}

.top-right-content button{height: 28px;font-size: 12px;margin-top: 2px;}
.top-right-content .find-txt{width: 170px;height: 28px !important;font-size: 14px;}
.top-right-content{float: right;margin-bottom: 5px;}