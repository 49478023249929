.main-container-single-fs{margin-top:65px; margin-right: 20px; background: #FFF;padding:10px;}
.fs-select{width:250px !important;}

.generating-section-single{font-family: 'Roboto', sans-serif;position: relative;padding: 0px !important;margin-left:0px; background: #FFF; min-height: 100px;border-top: 1px solid #ECF0F1;width: 795px;overflow: hidden;}
.gs-title{font-family: 'Roboto', sans-serif;padding-top: 15px;padding-bottom: 15px;padding-left: 38px;position: relative;}
.main-heading-single{margin-bottom: 2px;font-weight: 700;color: #111D5E;font-size: 22px;}
.sub-heading-single{margin-bottom: 2px;font-weight: 700;color: #1f3994;font-size: 18px;}
.gs-title.sub-lang .main-heading-single{font-size: 20px;}
.gs-title.sub-lang .sub-heading-single{font-size: 16px;}
.generating-section-single .fsheet-logo{position: absolute;height: 60px;top: 7px;right: 45px;}
.generating-section-single .overlay-bg{position: absolute;width: 120%;background: linear-gradient(to right, transparent, rgba(130, 69, 210, .3));height: 21rem;border-radius: 15rem;left: -6rem;top: -8rem;transform: rotate(5deg);}
.generating-section-single .apexcharts-legend{overflow: hidden;}

.chart-title-col{font-family: 'Roboto', sans-serif;position: relative;margin-left: 0px;margin-bottom: 10px; color: #fff;padding: 7px 0px;font-size: 22px !important; font-weight: 700;background: #d24572;width: 101%;padding-left: 40px;}
.chart-title-col.sub-lang{font-size: 20px !important;}
.chart-title-col small{font-size: 14px;color: #5b5c63;font-weight: 700;}
.chart-title-col .year-view{position: absolute;right: 15px;top: 7px;padding: 7px 30px;color: #fff;font-size: 16px;}
.chart-title-col.sub-lang .year-view{top: 6px;font-size: 13px;}
.chart-containts{padding: 0px 33px;position: relative;}
.sub-title-single{font-family: 'Roboto', sans-serif;font-weight: 700;font-size: 16px;color: #111D5E;height: 40px;position: relative;z-index: 2;}
.chart-containts.sub-lang .sub-title-single{font-size: 13px;font-weight: 800;}

.chart-containts.sub-lang .apexcharts-text{font-size: 75%;}
.chart-containts.sub-lang .summary-item h6{font-size: 11px;}

.newcase-tbl{margin-bottom: 0px;margin-top: 65px;}
.newcase-tbl tr td{text-align: center;}
.newcase-tbl .noof-lbl{color: #FFF; background-color: #d04571;padding: 7px;border-radius: 4px;font-size: 12px;font-weight: 600;text-transform: uppercase;}
.newcase-tbl img{margin-bottom: 10px;}
.newcase-tbl h5{font-weight: 600;color: #d04571}

.chart-sub-card{background: #fff;padding: 10px;border-radius: 8px;margin-bottom: 5px;overflow: hidden;border: 1px solid #ccc;}
.chart-sub-card .subchart-content{overflow: hidden;}
.chart-sub-card .mchart-view{margin-top: -15px;}
.generating-section-single .mchart-view{margin-top: -15px;}
.generating-section-single .gender-view{padding: 10px;background: linear-gradient(140deg, #033FFF, #023af0);border-radius: 10px;}
.generating-section-single .gender-view.fmale{background: linear-gradient(140deg, #FF008B, #eb0282);}
.generating-section-single .gender-view img{margin-left: 18%;}
.generating-section-single .gender-view h6{font-weight: 600;font-size: 14px;color: #fff;}
.generating-section-single .gender-view h5{font-weight: 700;font-size: 22px;margin-bottom: 0px;margin-top: 10px;color: #fff;}

.summary-section{padding-top: 0px;}
.summary-item{padding:0px;margin-top: 2px;height: 65px;border-radius: 5px;position: relative;}
.summary-item.world .img-col img, .summary-item.world .data-col, .summary-item.world .percentage-sub{background: linear-gradient(to bottom, #0c3fca, #0069af);}
.summary-item.world .percentage-line-main .percentage{background: #035397;}
.summary-item.asia .img-col img, .summary-item.asia .data-col, .summary-item.asia .percentage-sub{background: linear-gradient(to bottom, #FF7600, #f75a1c);}
.summary-item.asia .percentage-line-main .percentage{background: #FF7600;}
.summary-item.sl .img-col img, .summary-item.sl .data-col, .summary-item.sl .percentage-sub{background: linear-gradient(to bottom, #b246be, #93329E);}
.summary-item.sl .percentage-line-main .percentage{background: #93329E;}
.summary-item .img-col{text-align: center;position: relative;padding: 0px;}
.summary-item img{width: 55px;margin-top: 5px;margin-left: 11px;padding: 5px;border-radius: 30px;}
.summary-item .data-col{padding:13px 10px;height: 50px;margin-top: 8px;border-radius: 4px;border-top-right-radius: 0px;border-bottom-right-radius: 0px;padding-right: 0px;padding-left: 6px;}
.summary-item .percentage-col{padding: 0px;margin-left: -1px;}
.summary-item .percentage-col .percentage-sub{height: 50px;margin-top: 8px;border-top-right-radius: 30px;border-bottom-right-radius: 30px;position: relative;}
.summary-item h6{font-weight: 600;margin-bottom: 5px;text-transform: uppercase;font-size: 13px;margin-top: 3px;color: #fff;}
.summary-item h5{font-weight: 600;color: #111D5E;margin-bottom: 3px;position: absolute;right: 2px;top: 2px;height: 46px;width: 55px;padding-top: 14px;font-size: 14px;z-index: 3;background: #fff;border-radius: 30px;text-align: center;}

/* .summary-item.world h5{color: #3a5ee0;}
.summary-item.asia h5{color: #fd9319;}
.summary-item.sl h5{color: #d24572;} */
.summary-item .percentage-line-main{position: relative;width: 100%;background: #ccc;border-radius: 4px;}
.summary-item .percentage-line-main .percentage{height: 10px;border-radius: 4px;}
.summary-section.both-view{padding-top: 0px;margin-bottom: 10px;}
.summary-section.both-view .summary-item img{width: 30px;}
.summary-section.both-view .summary-item{height: auto;}
.summary-section.both-view .summary-item .percentage-line-main .percentage{height: 6px;}
.summary-section.both-view .summary-item h5{right: 3px;top: 3px;}
/* .summary-section.both-view .summary-item h6{font-size: 9px;}
.mchart-view .apexcharts-legend{top: 4px !important;}
.mchart-view .apexcharts-legend .apexcharts-legend-series{height: 15px;} */
.linechart-main.both-view .apexcharts-text tspan{font-weight: 600;font-size: 11px;}

.chart-containts.sub-lang .apexcharts-datalabels text{font-size: 70%;}

.apexcharts-text tspan, .apexcharts-text, .apexcharts-datalabels{font-family: 'Arial', sans-serif !important;}