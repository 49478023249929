@font-face {
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
src: url(../roboto/Roboto-Light.ttf);
}
@font-face {
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
src: url(../roboto/Roboto-Regular.ttf);
}
@font-face {
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
src: url(../roboto/Roboto-Bold.ttf);
}
@font-face {
font-family: 'Roboto';
font-style: normal;
font-weight: 800;
src: url(../roboto/Roboto-Black.ttf);
}