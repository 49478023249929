.twofact-pielegend{padding-left: 0px;}
.twofact-pielegend .col-6{width: 47%;position: relative;font-size: 12px;font-weight: 700;padding: 6px 8px;margin-bottom: 4px;padding-left: 26px;border: 1px solid #ccc;border-radius: 8px;margin-left: 5px;}
.twofact-pielegend .col-6 div{width: 16px;height: 16px;position: absolute;border-radius: 6px;margin-left: -21px;margin-top: 2px;}
.twofact-pielegend .col-6 label{position: absolute;right: 8px;font-weight: 800;text-align: right;}

.twofact-linelegend{list-style: none;padding-left: 0px;}
.twofact-linelegend li{position: relative;font-size: 12px;font-weight: 700;padding: 0px;margin-bottom: 4px;padding-left: 35px;}
.twofact-linelegend li div{width: 12px;height: 12px;position: absolute;border-radius: 6px;margin-left: -18px;margin-top: 3px;}

.overall-based .chart-title-col{font-size: 22px !important;}
.overall-based .chart-title-col.sub-lang{font-size: 20px !important;}

.sub-title-single{font-size: 16px;}
.sub-title-single.sub-lang{font-size: 13px;}

.maleHeader{
    text-align: center; 
    font-size: 13px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    background: #033FFF;
    padding: 5px;
    width: 100px;
    padding: 3px 10px;
    border-radius: 15px;
}
.femaleHeader{
    text-align: center; 
    font-size: 13px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    background: #ff039e;
    padding: 5px;
    width: 100px;
    padding: 3px 10px;
    border-radius: 15px;
}