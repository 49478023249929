
.chartview-legend{position: absolute;right: 10px;top: 40px;max-width: 116px;}
.chartview-legend ul{list-style: none;padding-left: 0px;}
.chartview-legend ul li{font-size: 12px;font-weight: 600;margin-bottom: 1px;}
.chartview-legend ul li div{width: 10px;height: 10px;background: #ccc;border-radius: 6px;position: absolute;margin-left: -14px;margin-top: 4px;}

.summary-item .percentage-line-main{position: relative;width: 100%;background: #ccc;border-radius: 4px;}
.summary-item .percentage-line-main .percentage{height: 10px;border-radius: 4px;}
.summary-section.both-view{padding-top: 0px;margin-bottom: 10px;}
.summary-section.both-view .summary-item img{width: 30px;margin-left: 5px;margin-top: 9px;}
.summary-section.both-view .summary-item{height: auto;}
.summary-section.both-view .summary-item .percentage-line-main .percentage{height: 6px;}
.summary-section.both-view .summary-item h5{right: 2px;top: 3px;font-size: 13px;height: 35px;width: 50px;padding-top: 9px;}
.summary-section.both-view .summary-item h6{font-size: 12px;margin-top: -1px;}
.summary-section.both-view .summary-item .data-col{height: 40px;margin-top: 5px;}
.summary-section.both-view .summary-item .percentage-col .percentage-sub{height: 40px;margin-top: 5px;}


.linechart-main.both-view .apexcharts-text tspan{font-weight: 600;font-size: 11px;}

.both-view .gender-view img{height: 70px;width: auto;margin-left: 32%;}
.both-view h6.sub-content-header{text-align: center;margin:0px;margin-top: 5px;font-size: 12px;font-weight: 600;color: #fff;background: #033FFF;padding: 5px;width: 100px;padding-left: 20px;margin-left: -28px;border-radius: 15px;}
.both-view h6.sub-content-header.fmale{background: #FF008B;}
.both-view .chart-sub-card{padding: 10px;}
.both-view .mchart-view{margin-top: 0px;}

.chart-containts.sub-lang .summary-section.both-view .summary-item h6{font-size: 11px;}