.form-control{padding: 3px 5px;}

#pdfviewerModal .modal-body{height: 100vh;}


.uploading-stat{font-size: 15px; color:#148F77; font-weight: 600;}

.uploading-stat .spinner{
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
